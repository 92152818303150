<template>
  <span class="btn-group">
    <a
      class="btn btn-sm btn-outline-secondary d-inline-flex align-items-center"
      target="_blank"
      v-if="row.file"
      :href="row.fileURL"
    >
      <CloudDownloadIcon class="me-8" />
      Download
    </a>
    <router-link
      class="btn btn-sm btn-outline-secondary d-inline-flex align-items-center"
      v-if="row.content"
      :to="`/siswa/jadwal/${$route.params.id}/materi/${row.id}`"
    >
      <BookIcon class="me-8" />
      Detail
    </router-link>
  </span>
</template>

<script>
import BookIcon from 'bootstrap-icons/icons/book.svg';
import CloudDownloadIcon from 'bootstrap-icons/icons/cloud-download.svg';

export default {
  components: { BookIcon, CloudDownloadIcon },

  props: ['row'],
};
</script>
