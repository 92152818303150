<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <div class="mb-8" v-if="schedule">
      <ScheduleInfo :schedule="schedule" />
    </div>

    <Table
      class="align-middle"
      rowKey="id"
      :isLoading="$store.getters['materials/isFetching'](params)"
      :columns="columns"
      :rows="$store.getters['materials/get'](params)"
    />
  </main>
</template>

<script>
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import Schedule from '../../../../store/models/Schedule';

import ScheduleInfo from '../../../../components/student/schedule/ScheduleInfo.vue';
import Table from '../../../../components/table/Table.vue';
import TableActions from '../../../../components/student/schedule/material/TableActions.vue';

export default {
  components: { ScheduleInfo, Table },

  computed: {
    schedule() {
      return Schedule.query().withAllRecursive().find(this.$route.params.id);
    },

    params() {
      if (!this.schedule) return {};

      return merge(
        {
          filter: pick(this.schedule, ['grade_id', 'subject_id']),
          sort: { id: 'desc' },
        },
        this.$route.query
      );
    },

    columns() {
      return [
        { key: 'title', text: 'Judul' },
        {
          key: 'actions',
          component: TableActions,
          props: { class: 'text-end' },
        },
      ];
    },

    meta() {
      return this.$store.getters['schedules/getMeta'](this.params);
    },
  },

  metaInfo() {
    return {
      title: 'Materi',
    };
  },

  methods: {
    fetch() {
      return this.$store.dispatch('materials/maybeFetch', this.params);
    },
  },

  watch: {
    '$route.query': {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },

  async beforeMount() {
    if (!this.schedule) {
      await this.$store.dispatch('schedules/maybeFetch', {
        filter: { id: this.$route.params.id },
      });
    }

    this.fetch();

    this.$store.commit('info/setBreadcrumb', [
      { path: '/siswa/jadwal', label: 'Jadwal' },
      {
        path: this.schedule.scheduleRoutePath,
        label: this.schedule.subject.name,
      },
    ]);
  },
};
</script>
